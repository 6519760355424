import React, { useContext } from "react"
import { navigate } from "gatsby"
import { CardGroup, Card } from "react-bootstrap"
import CustomButton from "./button"
import { GlobalContext } from "../context/provider.js"
import Facebook from "../assets/facebook-secondary.svg"
import Instagram from "../assets/instagram-secondary.svg"
import Banner from "./banner"
import "./acknowledgement.css"

const Acknowledgement = () => {
  const { dispatch } = useContext(GlobalContext)

  return (
    <>
      <div className="acknowledgement">
        <Banner />
        <div className="acknowledgement-body">
          <h1>Congratulations!</h1>
          <p>
            You’re on your way to a <strong>better home loan.</strong>
          </p>
          <p>
            Thanks for reaching out to the Compare n Save team. We’ll be in
            touch shortly with an invitation to book a complimentary home loan
            review so we can further discuss your preferred home loan option and
            outline the next steps. - so keep an eye out for an email from us.
          </p>
          <p>
            In the meantime, why not explore the latest industry trends and
            insights from the Compare n Save experts?
          </p>
          <CustomButton
            variant="secondary"
            className="acknowledgement-btn"
            onClick={() => {
              navigate("/blogs")
            }}
          >
            View insights
          </CustomButton>
        </div>
        <div className="acknowledgement-card">
          <div className="acknowledgement-card-body">
            <CardGroup>
              <Card>
                <Card.Body>
                  <Card.Title>
                    Stay across the
                    <br />
                    <strong>
                      latest news
                      <br />
                      and updates
                    </strong>
                  </Card.Title>
                  <div className="acknowledgement-card-social-media">
                    <a
                      href="https://www.facebook.com/profile.php?id=100063955214155"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Facebook className="icon" />
                    </a>
                    <a
                      href="https://www.instagram.com/comparensave.com.au/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Instagram className="icon" />
                    </a>
                  </div>
                </Card.Body>
              </Card>

              <Card>
                <Card.Body>
                  <Card.Title>
                    Quickly compare
                    <br />
                    <strong>
                      hundreds of
                      <br />
                      home loans
                    </strong>
                  </Card.Title>
                  <CustomButton
                    variant="secondary"
                    className="acknowledgement-card-button"
                    onClick={() => {
                      dispatch({ type: "SET_REFINANCE", refinance: false })
                      navigate("/product")
                    }}
                  >
                    Find a loan
                  </CustomButton>
                </Card.Body>
              </Card>
            </CardGroup>
          </div>
        </div>
      </div>
    </>
  )
}

export default Acknowledgement
